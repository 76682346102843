const FormatDateItem = ({dt, type, html, swicthDate}) => {

  let year            = '';
  let era_ja_JP       = '';
  let era_ja_JP_digit = '';
  let era_ja_JP_unit  = '';
  let month           = '';
  let month_digit     = '';
  let month_unit      = '';
  let day             = '';
  let day_digit       = '';
  let day_unit        = '';
  let weekday         = '';

    if (type === 'dateEnUS') {
      const options = { year:'numeric', month:'short', day:'numeric', hour12:false, weekday:'long' };
      return new Intl.DateTimeFormat('en-US', options).format(dt);
    }

    if (type === 'year') {

      if(swicthDate === true) {

        year = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {year:'numeric'}).format(dt);

        if(html === 'html') {

          era_ja_JP = year.match(/^\D+/gi);
          era_ja_JP_digit = year.match(/[0-9]+/gi);
          era_ja_JP_unit  = year.match(/\D+$/gi);
            return <span>{era_ja_JP}<span className="digit">{era_ja_JP_digit}</span>{era_ja_JP_unit}</span>;
        }
      }

        year = new Intl.DateTimeFormat('ja-JP', {year: 'numeric'}).format(dt);

        if(html === 'html') {
          era_ja_JP = year.match(/^\D+/gi);
          era_ja_JP_digit = year.match(/[0-9]+/gi);
          era_ja_JP_unit  = year.match(/\D+$/gi);
          return <span>{era_ja_JP}<span className="digit">{era_ja_JP_digit}</span>{era_ja_JP_unit}</span>;
        }

    }

    if (type === 'month') {

      month = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {month: 'numeric'}).format(dt);

      if(html === 'html') {
        month_digit = month.match(/^[0-9]+/gi);
        month_unit  = month.match(/\D+$/gi);
        return <span><span className="digit">{month_digit}</span>{month_unit}</span>;
      }

      return (new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {month: 'numeric'}).format(dt));
    }

    if (type === 'day') {

      day = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {day: 'numeric'}).format(dt);

      if(html === 'html') {
        day_digit = day.match(/^[0-9]+/gi);
        day_unit  = day.match(/\D+$/gi);
        return <span><span className="digit">{day_digit}</span>{day_unit}</span>;
      }
      return (new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {day: 'numeric'}).format(dt));
    }
    if (type === 'weekday') {

      weekday = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {weekday: 'short'}).format(dt);

      if(html === 'html') {
        return <span className="weekday_ja_JP">({weekday})</span>;
      }

      return (new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {weekday: 'short'}).format(dt));
    }         
      return 'タイプ未指定';
  }

  export default FormatDateItem;
