
const TimeFormatted = ({dt, viewSecond, type}) => {

    let hour   = ""; 
    let minute = ""; 
    let second = ""; 
    let colon  = ":";

    hour   = dt.getHours().toString().padStart(2, '0');
    minute = dt.getMinutes().toString().padStart(2, '0');
    second = dt.getSeconds().toString().padStart(2, '0');

    if (type === 'dateEnUS') {
        let ampm = '';
        let options = '';
        let hourUS = '';
        options = { hour: 'numeric', hour12: true };
        hourUS =  new Intl.DateTimeFormat('en-US', options).format(dt);
        [hour, ampm]  = hourUS.split(' ');

        return <span className="small">{hour}<span className="blink">{colon}</span>{minute} {ampm}</span>;       
    }

    if (viewSecond === true) {
    return <span className="small">{hour}<span className="">{colon}</span>{minute}<span className="">{colon}</span>{second}</span>;
    };

    return <span>{hour}<span className="blink">{colon}</span>{minute}</span>;

}

export default TimeFormatted;
