import React from 'react';
import {useState, useEffect} from "react";
import '../css/index.css';
import FormatDateItem from './FormatDateItem';
import TimeFormatted from './TimeFormatted';

function Index(props) {

    const [now, setNow] = useState(new Date());
    const [swicthDate, setSwicthDate] = useState(false);
    const [viewSecond, setViewSecond] = useState(false);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
          setNow(new Date());
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }, [now]);
    return (
      <div>
      <div className="date" onClick={() => setSwicthDate(!swicthDate)}>
        <h2>
          <FormatDateItem dt={now} type='year' html='html' swicthDate={swicthDate} />
          <FormatDateItem dt={now} type='month' html='html' />
          <FormatDateItem dt={now} type='day' html='html' />
          <FormatDateItem dt={now} type='weekday' html='html' />
        </h2>
      </div>  
        <h1 onClick={() => setViewSecond(!viewSecond)}><TimeFormatted dt={now} viewSecond={viewSecond} /></h1>

      </div>
      );
    
  }
  
  export default Index;
  