import React from 'react';
import {useState, useEffect} from "react";
import '../css/index.css';
import FormatDateItem from './FormatDateItem';
import TimeFormatted from './TimeFormatted';

function ClockEnUS(props) {

    const [now, setNow] = useState(new Date());
    const [viewSecond, setViewSecond] = useState(false);

    useEffect(() => {
      const timeoutId = setTimeout(() => {
          setNow(new Date());
      }, 1000);
      return () => {
        clearTimeout(timeoutId);
      };
    }, [now]);

    return (
      <div>
      <div className="date">
        <h2><FormatDateItem dt={now} type='dateEnUS' html='html' />
        </h2>
      </div>  
        <h1 onClick={() => setViewSecond(!viewSecond)}><TimeFormatted dt={now} viewSecond={viewSecond} type='dateEnUS' /></h1>

      </div>
      );
    
  }
  
  export default ClockEnUS;
  