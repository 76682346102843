import React from 'react';
import '../css/index.css';
import {useState} from "react";

function AgeCalculator() {

    const [birthYear, setBirthYear] = useState('');
    const [termYear, setTermYear] = useState(0);
    const [age, setAge] = useState(false);
    let now = new Date();
    let nowYear = now.getFullYear();

    function handleClick(e) {
        if(!birthYear) {
            return;
        }
        if(!birthYear.match(/^[0-9]{1,4}$/gi)) {
            return;
        }
        e.preventDefault();
        let year = nowYear - birthYear - 1;
        setTermYear(year);
        setAge(true);
    }

    return (
        <div>
            <div className="ad">広告</div>
            <div id="age_calculator">
            <h1>年齢計算</h1>
            <input type="tel" className="input-year" name="birth_date" placeholder="西暦４桁" maxLength='4' value={birthYear} onChange={(e) => setBirthYear(e.target.value)} />
                <button type="submit" className="button-submit" onClick={handleClick}>計算</button>
                
            {age &&
            <div className="result fade">
                <div className="text">誕生日前なら</div>
                <div className="age">{termYear}歳</div>
            </div>
            }
            </div>
        </div>
      );
    
  }
  
  export default AgeCalculator;
  