import React from 'react';
import {useState, useRef} from "react";
import Index from './components/Index';
import AgeCalculator from './components/AgeCalculator';
import ClockEnUS from './components/ClockEnUS';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import useCookie from "./components/useCookie";

function Modal({show, setShow, content, displayModal}){
  const [cookie, updateCookie] = useCookie("userAgreement", 1);

  const closeModal = () => {
    setShow(false);
    if(cookie !== "1") {
      updateCookie(1, 1);
    }
  }

  if (show && displayModal.current) {
    return (
      <div id="overlay">
        <div id="modalWindow" onClick={(e) => e.stopPropagation()}>
          <p>{content}</p>
          <button onClick={() => closeModal()}>同意</button>
        </div>
      </div >
    )
  } else {
    return null;
  }
}

export default function App() {
  const [show, setShow] = useState(true)
  const [cookie] = useCookie("userAgreement", 0);
  let modal = true;

if (cookie === "1") {
  modal = false;
} else {
  modal = true;
}

const displayModal = useRef();
displayModal.current = modal;

  return (
    <Router>
    <div>
    <div className="modal">
      <Modal show={show} setShow={setShow} setcookieShow={cookie} displayModal={displayModal} content="このウェブアプリは、ユーザー体験向上のためCookieおよびGoogleアナリティクスを使用しています。" />
    </div>

        <Switch>
        <Route path="/" exact render={() => <Index />} />
        <Route path="/age-calculator" render={() => <AgeCalculator />} />
        <Route path="/clock-en-US" render={() => <ClockEnUS />} />
        </Switch>
 
    <footer>
      <nav>
        <ul>
            <li><Link to="/"><i className="far fa-clock"></i>時計</Link></li>
            <li><Link to="/age-calculator"><i className="far fa-user"></i>年齢計算</Link></li>
            <li><Link to="/clock-en-US"><i className="fas fa-language"></i>米国式</Link></li>
        </ul>
      </nav>
      
        <div className="copyright">© Nuage Creative / © <a href="https://fontawesome.com/license/free">Font Awesome</a></div>
    </footer>
    </div>
    </Router>

  );
}


